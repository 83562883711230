import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import ReactPlayer from "react-player";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <>{/*
      <ReactPlayer
        url="https://player.vimeo.com/video/490041325"
        playing={true}
        loop={true}
        width="100%"
        height="100%"
        style={{
          position: "absolute",
          paddingTop: "20px",
          width: "100%",
          left: "50%",
          right: "50%",
          height: "100%",
          objectFit: "cover",
          transform: "translate(-50%, -50%)",
          zIndex: "-1",
          volume: "0",
        }}
      /> */}
      <section {...props} className={outerClasses}>
        <div className="container-sm">
          <div className={innerClasses}>
            <div className="hero-content">
              <h1
                className="mt-0 mb-16 reveal-from-bottom"
                data-reveal-delay="200"
              >
                Welcome to{" "}
                <span className="text-color-primary">
                  {" "}
                  <Image
                    src={require("./../../assets/images/logowideclear.png")}
                    alt="Open"
                    width={300}
                    height={300}
                  />
                </span>
              </h1>
              <div className="container-xs">
                <p
                  className="m-0 mb-32 reveal-from-bottom"
                  data-reveal-delay="400"
                >
                  Your <span className="text-color-primary"> Personal </span>{" "}
                  Farm Assistant
                </p>
                <div className="reveal-from-bottom" data-reveal-delay="600">
                  {/*
                  <ButtonGroup>
                    <Button tag="a" color="primary" wideMobile href="">
                      Get started
                    </Button>
                    <Button tag="a" color="dark" wideMobile href="">
                      Details
                    </Button>
                  </ButtonGroup>*/}
                </div>
              </div>
            </div>
            <div
              className="hero-figure reveal-from-bottom illustration-element-01"
              data-reveal-value="20px"
              data-reveal-delay="800"
            ></div>
          </div>
        </div>
      </section>
    </>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
