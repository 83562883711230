import React from "react";
import Image from "../../elements/Image";

export default function FeatureItem({
  featureImage,
  featureTitle,
  featureText,
}) {
  return (
    <>
      <div className="tiles-item reveal-from-bottom">
        <div className="tiles-item-inner">
          <div className="features-tiles-item-header">
            <div className="features-tiles-item-image mb-16">
              <Image
                src={require("./../../../assets/images/" + featureImage)}
                alt="Features tile icon 01"
                width={64}
                height={64}
              />
            </div>
          </div>
          <div className="features-tiles-item-content">
            <h4 className="mt-0 mb-8">{featureTitle}</h4>
            <p className="m-0 text-sm">{featureText}.</p>
          </div>
        </div>
      </div>
    </>
  );
}
